@import '../../assets/scss/main.scss';
@import '../../assets/scss/variables.scss';
.full-content {
    .profile-list {
        border-spacing: 0px;
        tr {
            background: #fff;
            &:hover {
                background: #f4f5f7;
            }
            th {
                vertical-align: middle;
                border-bottom: none;
                background: #FAFAFA;
                color: #9F9F9F;
                font-weight: 400;
                font-size: 18px;
                line-height: 91.65%;
                font-family: $fontfamilyArialRegular;
                height: 56px;
            }
            td {
                color: #9F9F9F;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 91.65%;
                font-family: $fontfamilyArialRegular;
                border: none;
                vertical-align: middle;
                padding: 0.2rem 0.75rem;
                height: 56px;
                border-bottom: 1px solid #EDEDED;
                .btnsGroup.reviewBtns {
                    button.btn.btn-primary {
                        padding: 0;
                        background: transparent;
                        border: none;
                        margin: 0 5px;
                    }
                }
            }
        }
    }
}

table.profile-list.table {
    .invoiceImage {
        img {
            width: 70px;
            height: 70px;
            object-fit: cover;
        }
    }
}