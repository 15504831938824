@import '../../../assets/scss/main.scss';
@import '../../../assets/scss/variables.scss';

.wrapper {
    .full-content {
        .main-content {
            .tabs-btn {
                display: flex;
                align-items: center;

                .nav-pills {

                    .nav-link.active,
                    .nav-pills .show>.nav-link {
                        background: #6DB0E1;
                        border-radius: 5px;
                        color: #fff;
                    }

                    .nav-item {
                        margin-right: 15px;

                        a {
                            font-weight: 400;
                            font-size: 25px;
                            line-height: 23px;
                            color: #A2A2A2;
                            font-family: $fontfamilyAssistant;
                        }
                    }
                }
            }

            .tabs-btn {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;
                padding-bottom: 10px;
                border-bottom: 1px solid #C6C5C3;

                .tab-search {
                    display: flex;
                    align-items: center;

                    .nav.nav-pills {
                        flex-wrap: nowrap;
                    }
                }

                button.new-btn.btn.btn-primary {
                    background: #6DB0E1;
                    border-radius: 5px;
                    border-color: #6DB0E1;
                    height: 46px;
                    width: 80px;
                    font-size: 25px;
                    line-height: 91.65%;
                    color: #FFFFFF;
                }
            }

            .tabing-content {
                .tabing-content-inner {
                    .search-point {
                        display: flex;
                        margin-bottom: 40px;

                        form.search-box {
                            max-width: 310px !important;
                            width: 100%;
                            position: relative;
                            margin-right: 15px;

                            input.form-control {
                                border-radius: 5px;
                                height: 49px;
                                padding-left: 50px;
                                font-size: 22px;
                                line-height: 20px;
                                background: #FFFFFF;
                                border: 1px solid #A2A2A2;

                                &:focus {
                                    box-shadow: none;
                                }
                            }

                            button.btn.btn-outline-success {
                                position: absolute;
                                left: 0;
                                top: 0;
                                bottom: 0;
                                border: none;

                                &:hover,
                                &:focus {
                                    background-color: transparent;
                                    box-shadow: none;
                                }
                            }
                        }

                        button.export-btn.btn.btn-primary {
                            font-size: 22px;
                            line-height: 91.65%;
                            color: #716F6F;
                            height: 49px;
                            border: none;
                            padding: 10px 16px;
                            background: #FFFFFF;
                            border: 1px solid #A2A2A2;
                            border-radius: 5px;
                        }
                    }

                    .tab-content {
                        .table.porfile-list {
                            border-collapse: separate;
                            border-spacing: 0 3px;

                            thead {
                                th {
                                    vertical-align: bottom;
                                    border-bottom: none;
                                    background: #6DB0E1;
                                    color: #fff;
                                    font-weight: 400;
                                    font-size: 22px;
                                    line-height: 91.65%;
                                }
                            }

                            tr {
                                background: #F5F5F5;

                                td {
                                    border: none;
                                    height: 48px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.profile-wrap {
    position: relative;
button.menuButton.btn.btn-primary, button.menuButton.btn.btn-primary:active {
    background: transparent;
    border: none;
    margin: 0 5px;
    position: absolute;
    left: -60px;
    top: 55px;
    z-index: 8;
    &:focus {
        box-shadow: none;
    }
    img {
        width: 32px; 
    }    
}
}
.menuToggle {
    .profile-wrap {
        button.menuButton.btn.btn-primary {
            left: -40px;
            transform: rotate(180deg);
        }
    }
}

/* Media Query start */

@media only screen and (max-width: 767px) {
    .wrapper {
        .main-sidebar {
            padding-top: 10px !important;
            top: 0;
            .logo-wrap {
                .nav-link {
                    padding: 0;
                    img {
                        width: 60px;
                    }
                }
            }
            .flex-column.nav {
                margin-top: 5px !important;
                a.nav-link {
                    padding: 4px 0px 4px 40px !important;
                    font-size: 16px !important;
                    margin: 2px 0 !important;
                }
                a.manage.nav-link, a.review.nav-link, a.statistic.nav-link {
                    background-size: 18px 18px !important;
                }
            }
        }
        .full-content {
            .main-content {
                .tabing-content {
                    .tabing-content-inner {
                        .search-point {
                            .export-btn.btn.btn-primary {
                                font-size: 18px !important;
                                height: 40px !important;
                                padding: 8px 16px !important;
                                display: flex;
                                align-items: center;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 480px) {
    .wrapper {
        .main-sidebar {
            width: 190px !important;
        }
        .full-content {
            .header {
                .profile-wrap {
                    button#dropdown-item-button {
                        width: 45px !important;
                        height: 45px !important;
                    }
                }
            }
            .main-content {
                .tabs-btn {
                    .nav-pills {
                        .nav-item {
                            a.nav-link {
                                font-size: 14px;
                                padding: 0.5rem 1.25rem;
                            }
                        }
                    }
                }
            }
        } 
    }
}