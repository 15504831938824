// fontfamily
$fontfamilyAnton: 'Anton', sans-serif;
$fontfamilyAssistant: 'Assistant', sans-serif;
$fontfamilyArialRegular: 'ARIAL-Regular';

// fontsize
$fontsize14px: 14px;

// color Properties
$colorB2C1C0: #B2C1C0;
$color6DB0E1: #6DB0E1;

// fontweight
$fontweight400: 400;
$fontweight500: 500;
$fontweight600: 600;
$fontweight700: 700;

// display properties
$displayInlineBlock: inline-block;

// Width properties
$width100per: 100%;
$width250px: 250px;

// Height Properties
$height100per: 100%;
$height100vh: 100vh;
$height25px: 25px;

// Padding Properties
$padding5px: 5px;

// position Properties
$positionRelative: relative;
$positionAbsolute: absolute;
$positionFixed: fixed;